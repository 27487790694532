import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Avatar, Grid, Snackbar } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import logo from './logo-ivs-black.png'; // Importă imaginea logo-ului

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false); // Control pentru deschiderea Snackbar-ului

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username === 'admin' && password === 'password') {
     // alert('Autentificare reușită');
      setError('');
      setOpenSnackbar(false); // Închide Snackbar-ul
    } else {
      setError('Nume de utilizator sau parolă incorecte');
      setOpenSnackbar(true); // Deschide Snackbar-ul pentru eroare
    }
  };

  // Funcție pentru a închide Snackbar-ul
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container
      component="main"
      maxWidth={false} // Limitare a lățimii pentru ecrane mai mari
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        padding: 0,
        overflow:'hidden',
      }}
    >
      {/* Fundal video */}
      <video
  autoPlay
  loop
  muted
  style={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',         // Ensure the video always covers the full width
    height: '100vh',       // Ensure the video always covers the full height of the viewport
    objectFit: 'cover',    // Ensures the video covers the area without distortion
    zIndex: -1,            // Ensures the video is behind the content
  }}
>
  <source src="/bglogin.mp4" type="video/mp4" />
</video>

      {/* Logo */}
      <Box sx={{ marginBottom: 4, textAlign: 'center' }}>
        <img src={logo} alt="Logo" style={{ width: '70%', maxWidth: '450px', objectFit: 'contain' }} />
      </Box>

      {/* Caseta de autentificare */}
      <Box
        sx={{
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.9)', // Transparanță mai ușoară pentru contrast mai bun
          borderRadius: '16px',
          boxShadow: '0 15px 30px rgba(0, 0, 0, 0.1)',
          width: '100%',
          maxWidth: '380px',
          zIndex: 1, // Asigură că conținutul este deasupra videoclipului
        }}
      >
        {/* Avatar */}
        <Avatar
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            marginBottom: 3,
            width: 60,
            height: 60,
            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.15)',
          }}
        >
          <LockOutlinedIcon sx={{ fontSize: 30 }} />
        </Avatar>

        {/* Titlu */}
        <Typography component="h1" variant="h5" sx={{ fontWeight: 700, color: '#333', fontSize: '1.3rem' }}>
          Bun Venit !
        </Typography>

        {/* Formular */}
        <form onSubmit={handleSubmit} sx={{ width: '100%', marginTop: 3 }}>
          <Grid container spacing={2}>
            {/* Câmp utilizator */}
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Nume de utilizator"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': { borderRadius: '12px' },
                  '& .MuiInputLabel-root': { color: '#555' },
                  '& .MuiInputBase-root': { paddingLeft: '14px', paddingRight: '14px' }, // Padding mai subțire
                }}
              />
            </Grid>

            {/* Câmp parolă */}
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Parolă"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': { borderRadius: '12px' },
                  '& .MuiInputLabel-root': { color: '#555' },
                  '& .MuiInputBase-root': { paddingLeft: '14px', paddingRight: '14px' },
                }}
              />
            </Grid>

            {/* Buton de autentificare */}
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  marginTop: 3,
                  padding: '12px',
                  fontWeight: 600,
                  borderRadius: '12px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.primary.dark,
                    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)',
                  },
                }}
              >
                Autentificare
              </Button>
            </Grid>
          </Grid>
        </form>

        {/* Text subtire */}
      </Box>

      {/* Snackbar pentru eroare */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={error}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ zIndex: 1300 }} // Asigură că snackbar-ul este deasupra conținutului
      />
    </Container>
  );
};

export default Login;
