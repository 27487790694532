import React from 'react';
import Login from './Login';  // Import your Login component
import './App.css';  // You can still use your custom CSS here

function App() {
  return (
    <div className="App">
      {/* Render the Login component */}
      <Login />
    </div>
  );
}

export default App;
